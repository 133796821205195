<!--
 * @Description: 公众号 停车缴费 payforParking
 * @Author: zhoucheng
 * @Github: 无
 * @Date: 2021-04-01 00:16:35
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="停车缴费" />
    <div class="top-box">
      <div class="top-right">
        <div class="top-right-top"
             @click="handleClickOtherCars">
          <div class="left">
            <div class="title">车牌查询代缴</div>
            <div class="text">
              <span class="text1">输入车牌</span>
              <span class="text2">便捷缴费</span>
            </div>
          </div>
          <div class="right">
            <van-image style="width: 100%;"
                       :src="require('@/assets/wxParForParking/searchCar.png')"></van-image>
          </div>
        </div>
        <div class="top-right-bottom"
             @click="handleClickSpackPark">
          <div class="left">
            <div class="title">车位查询代缴</div>
            <div class="text">
              <span class="text1">车位信息</span>
              <span class="text2">亦可缴费</span>
            </div>
          </div>
          <div class="right">
            <van-image style="width: 100%;"
                       :src="require('@/assets/wxParForParking/computer.png')"></van-image>
          </div>
        </div>
      </div>
    </div>
    <div class="bind-car">
      <div>
        <van-image style="width: 20px;margin: 0 10px;"
                   :src="require('@/assets/wxParForParking/bindCar.png')"></van-image>
        <span>绑定车辆</span>
      </div>
    </div>
    <van-row class="main">
      <van-row class="tableBox">
        <van-row class="table">
          <!-- 在停 -->
          <van-row class="tablePartBox"
                   v-for="item in orderList"
                   :key="item.plateNumber">
            <van-col :span="16">
              <van-row class="plateNumber">
                <span>{{item.plateNumber}}</span>
                <span class="brand">{{colorObj[item.numberPlateColorCode]}}</span>
              </van-row>
              <van-row style="margin-left:12px;">
                <van-image width="16"
                           height="14"
                           :src="require('@/assets/wxParForParking/backCar.png')"
                           style="margin-top:17px;" />
                <span class="findCar"
                      @click="handleClickFindCar(item)">反向寻车</span>
              </van-row>
            </van-col>
            <van-col :span="8">
              <van-row class="state">在停</van-row>
              <van-row class="details"
                       @click="handleClickInfo(item)">
                订单详情
                <van-image width="8"
                           height="8"
                           :src="require('@/assets/wxParForParking/details.png')" />
              </van-row>
            </van-col>
          </van-row>
          <!-- 未停 -->
          <van-row class="tablePartBox"
                   v-for="(item,index) in plateList"
                   :key="index">
            <van-col :span="21">
              <van-row class="plateNumber">
                {{item.plateNumber}}<span class="brand">{{colorObj[item.numberPlateColorCode]}}</span>
              </van-row>
              <van-row style="width:100%;margin-left:12px;margin-top:10px">
                <span style="font-size:16px">未停</span>
                <span style="color: #333333;font-weight: 400; font-size: 15px;float:right"
                      v-if="item.lackOrderNumber!=0"
                      @click="handleClickItemLackOrder(item)">
                  待缴订单
                  <van-image width="8"
                             height="8"
                             :src="require('@/assets/wxParForParking/details.png')" />
                  {{item.lackOrderNumber}} 单
                </span>
              </van-row>
            </van-col>
          </van-row>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import getUserInfo from '@/utils/getUserInfo.js'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      plateList: [], // 车牌列表
      orderList: [], // 订单列表
      plateColorList: [], // 车牌颜色列表
      colorObj: {} // 获取所有车牌颜色
    }
  },
  // 监听属性 类似于data概念
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    getUserInfo().then(() => {
      this.getOnlineOrderList()
    })
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前+
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 点击待缴订单
    handleClickItemLackOrder (item) {
      this.$router.push({
        name: 'lackOrder',
        query: {
          plateNumber: item.plateNumber,
          numberPlateColorCode: item.numberPlateColorCode
        }
      })
    },
    // 获取在线订单
    getOnlineOrderList () {
      this.getColorDict()
      this.plateList = []
      this.orderList = []
      this.$payForParking.queryOnlineOrderByPlateNumber({}).then(res => {
        res.resultEntity.forEach(item => {
          if (item.order) {
            this.orderList.push(item)
          } if (item.order === undefined) {
            const that = this
            const info = {
              plateNumber: item.plateNumber,
              numberPlateColorCode: item.numberPlateColorCode
            }
            that.$lackOrder.queryArrangeOrder(info).then(Response => {
              item.lackOrderNumber = Response.resultEntity.length
              that.plateList.push(item)
            })
          }
        })
      })
    },
    // 订单详情点击跳转
    handleClickInfo (item) {
      this.$router.push({
        name: 'parForParkCount',
        query: {
          plateNumber: item.plateNumber,
          numberPlateColorCode: item.numberPlateColorCode
        }
      })
    },
    // 反向寻车
    handleClickFindCar (item) {
      this.$router.push({
        name: 'reverseSearch',
        query: { parkId: item.parkId }
      })
    },
    // 车牌代缴点击跳转
    handleClickOtherCars () {
      this.$router.push('/payForBehalf')
    },
    // 点击跳转车位查询代缴
    handleClickSpackPark () {
      this.$router.push({ path: '/payForSpacePark' })
    },
    // 获取车牌颜色
    getColorDict () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2'
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.plateColorList = res.resultEntity
        const colorObj = {}
        this.plateColorList.forEach(item => {
          colorObj[item.code] = item.name
        })
        this.colorObj = colorObj
      })
    }
  }
}

</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: PingFang-SC;
  .top-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px 0;
    background: #ffffff;
    .title {
      width: 80%;
      color: #333333;
      font-size: 15px;
      font-weight: 600;
      line-height: 23px;
      white-space: nowrap;
    }
    .text {
      width: 80%;
      margin-bottom: 0px;
      color: #8dafc2;
      font-size: 11px;
      line-height: 23px;
      white-space: nowrap;
      .text1 {
        margin-right: 5px;
      }
    }
    .top-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 93.6%;
      height: 170px;
      border-radius: 5px;
      .top-right-top {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 48%;
        height: 47%;
        padding: 10px 10px;
        background: #fff0e9;
        border-radius: 5px;
        box-sizing: border-box;
        overflow: hidden;
        .left {
          width: 75%;
          height: 100%;
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 32%;
          height: 100%;
          margin-right: -10px;
          margin-bottom: -35px;
        }
      }
      .top-right-bottom {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 48%;
        height: 47%;
        padding: 10px 10px;
        background: #e0fdf1;
        border-radius: 5px;
        box-sizing: border-box;
        .left {
          width: 75%;
          height: 100%;
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 27%;
          height: 100%;
        }
      }
    }
  }
  .bind-car {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .main {
    height: calc(100% - 316px);
    overflow: auto;
    .tableBox {
      .table {
        .tablePartBox {
          width: 93.6%;
          height: 96.9px;
          margin: 12.5px 3.6%;
          background-color: #ffffff;
          border-radius: 5px;
          .plateNumber {
            display: flex;
            font-size: 25px;
            color: #333333;
            font-weight: 500;
            margin-top: 19.9px;
            margin-left: 12px;
            .brand {
              display: block;
              margin: 7px 0 5px 12.5px;
              color: #fff;
              background: #597fc5;
              border-radius: 5px;
              font-size: 13px;
              line-height: 17px;
              width: 34px;
              height: 17px;
              text-align: center;
            }
          }
          .findCar {
            color: #19a9fc;
            font-size: 13px;
            font-weight: 400;
            margin-left: 12px;
            margin-top: -3px;
          }
          .state {
            color: #333333;
            font-weight: 400;
            font-size: 15px;
            margin-top: 24.8px;
          }
          .details {
            margin-top: 18px;
            color: #333333;
            font-weight: 400;
            font-size: 15px;
          }
        }

        .tableNotPartBox {
          width: 93.6%;
          height: 96.9px;
          margin: 12.5px 3.6%;
          background-color: #bfbfbf;
          border-radius: 5px;
          .plateNumber {
            display: flex;
            font-size: 25px;
            color: #333333;
            font-weight: 500;
            margin: 19.9px 0 11px 12px;
            .notBrand {
              display: block;
              margin: 7px 0 5px 12.5px;
              color: #bfbfbf;
              background: #fff;
              border-radius: 5px;
              font-size: 13px;
              line-height: 17px;
              width: 34px;
              height: 17px;
              text-align: center;
            }
          }
          .notFindCar {
            color: #fefefe;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
